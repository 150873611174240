import React, { useEffect, useState } from "react";
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineWhatsApp } from 'react-icons/ai';
import { Link } from "react-scroll";
import { useTransition, animated } from "react-spring";
import { BsX } from "react-icons/bs";
import client, { urlFor } from "../sanity";
import offerImage from "../utils/offer.jpeg"
const OfferPopup = () => {
  const [offer, setOffer] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "offer"]{
            ...,
          }`
      )
      .then((data) => {
        setOffer(data);
      });
  }, []);
  const [showPoster, setShowPoster] = useState(true);
  const transition = useTransition(showPoster, {
    from: { x: 0, y: 1000, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: 0, y: 1000, opacity: 0 },
  });

  return (
    <>
      {offer[0]?.active === true ? (
        <>
          {transition((style, item) =>
            item ? (
              <animated.div
                style={style}
                className=" flex-row
                fixed top-0 left-0 w-full h-screen bg-[#00000033] flex justify-center items-center z-50"
              >
                {offer.length > 0 && (
                  <div className="relative w-[80%] xl:w-[50%] bg-whit h-max-[200px] rounded-lg justify-center flex z-30">
                    <div className="relative mx-auto flex justify-center items-center">
                      <div className="flex self-center flex-row justify-between p-4 items-center">
                        <BsX
                          onClick={() => setShowPoster(false)}
                          color="white"
                          className="absolute top-0 right-0 m-[20px] text-2xl cursor-pointer"
                        />
                        <img src={offerImage} className="rounded-md" />
                      </div>
                      {/* <div className="w-[50%] flex justify-center items-center">
                        <img
                          alt="brownies"
                          src={urlFor(offer[0]?.image).url()}
                          className="w-full relative"
                        />
                      </div>
                      <div className="text-[#B02D28] lg:mx-5  text-lg flex flex-col justify-center ">
                        <h2 className="font-bold text-3xl">{offer[0].title}</h2>
                        <h2 className="">{offer[0].description}</h2>
                        <Link
                          to="Doğal çilek suyu"
                          offset={-160}
                          onClick={() => setShowPoster(false)}
                        >
                          <a className='p-[15px]' href='https://www.instagram.com/waffly.tr/' rel='noreferrer' target='_blank'>
                          <p className="mt-3 gap-1 cursor-pointer flex justify-center items-center w-max bg-[#F0F0F0] ease-in-out duration-75 px-4 py-2 rounded-3xl text-black">
                            Keşefet
                            <AiOutlineInstagram />
                            <span className="text-sm">
                              waffly.tr
                            </span>
                          </p>
                          </a>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                )}
              </animated.div>
            ) : (
              <></>
            )
          )}
        </>
      ) : (
        <></>
      )
      }
    </>
  );
};

export default OfferPopup;
